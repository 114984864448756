<template>
    <div class="managsystem-content-operation">
        <div class="block-box">
            <div class="head-box">
                <div class="title">累计数据统计</div>
                <div class="date-filter">
                    <DatePicker type="date" :options="datePickerOptions" v-model="date" placeholder="选择日期" style="width: 300px" />
                </div>
            </div>
            <div class="content-box">
                <div class="note-box">
                    <div class="item-box" v-for="(item, idx) in chartModuleList" :key="idx">
                        <div class="title">{{ item.title }}</div>
                        <div class="time">{{ dateStr }} 00:00</div>
                        <div class="value-box">
                            <NumberRoll :number="item.value"></NumberRoll>
                            <p class="unit">{{ item.unit }}</p>
                        </div>
                        <div class="trend-box">
                            <div class="trend-item">
                                <p class="name">较1日前</p>
                                <p class="value" :style="{ color: item.valueDayAgo >= 0 ? '#06a561' : '#F0142F' }">{{ !item.valueDayAgo || item.valueDayAgo >= 0 ? "+" : "" }}{{ item.valueDayAgo || 0 }}{{ item.unit }}</p>
                            </div>
                            <div class="trend-item">
                                <p class="name">较15日前</p>
                                <p class="value" :style="{ color: item.value15Ago >= 0 ? '#06a561' : '#F0142F' }">{{ !item.value15Ago || item.value15Ago >= 0 ? "+" : "" }}{{ item.value15Ago || 0 }}{{ item.unit }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chart-module" v-for="(item, idx) in chartModuleList" :key="idx">
                    <div class="head-box">
                        <p class="title">各社区{{ item.title }}</p>
                        <p class="date">{{ dateStr }} 00:00</p>
                        <div class="download-btn" @click="onExport(item)">
                            <Icon type="md-cloud-download" />
                            <p class="name">下载</p>
                        </div>
                        <div class="more" @click="onDisplayDetail(item)">
                            <p class="name">更多详情</p>
                            <Icon type="ios-arrow-forward" />
                        </div>
                    </div>
                    <div class="chart-box">
                        <ElemChart class="chart-left" :option="item.pieOption"></ElemChart>
                        <ElemChart class="chart-right" :option="item.barOption"></ElemChart>
                    </div>
                </div>
            </div>
        </div>

        <CompModal ref="comp_modal" :title="detail.title" width="80%">
            <div class="managsystem-content-operation detail">
                <div class="block-box">
                    <div class="content-box">
                        <div class="chart-module">
                            <div class="head-box">
                                <p class="title-box">{{ detail.title }}</p>
                                <p class="date">{{ dateStr }} 00:00</p>
                                <div class="download-btn" @click="onExport(detail)">
                                    <Icon type="md-cloud-download" />
                                    <p class="name">下载</p>
                                </div>
                            </div>
                            <div class="chart-box">
                                <ElemChart class="chart-left" :option="detail.pieOption"></ElemChart>
                                <ElemChart class="chart-right" :option="detail.barOption"></ElemChart>
                            </div>
                        </div>
                    </div>

                    <div class="content-box">
                        <div class="chart-module">
                            <div class="head-box">
                                <p class="title-box">{{ detail.title }}</p>
                                <div class="date-filter">
                                    <DatePicker type="daterange" split-panels :options="datePickerOptions" v-model="detail.date" placeholder="选择日期" style="width: 300px" @on-change="getCommunityDetailData(detail)" />
                                </div>
                                <div class="download-btn" @click="onExportByDate(detail)">
                                    <Icon type="md-cloud-download" />
                                    <p class="name">下载</p>
                                </div>
                            </div>
                            <div class="checkbox-box">
                                <CheckboxGroup v-model="detail.community" @on-change="getCommunityDetailData(detail)">
                                    <Checkbox v-for="(item, idx) in communitys" :key="idx" :label="item.value">
                                        <span>{{ item.name }}</span>
                                    </Checkbox>
                                </CheckboxGroup>
                            </div>
                            <div class="chart-box">
                                <ElemChart class="chart" :option="detail.lineOption"></ElemChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import NumberRoll from "./childrens/NumberRoll.vue"
import ElemChart from "./childrens/ElemChart.vue"

import xlsx from "xlsx"
import Street from "./utils/street"
import DateUtil from "./utils/date"

export default {
    components: { ElemChart, CompModal, NumberRoll },

    data() {
        return {
            // 默认当前日期
            date: DateUtil.getDateByDay(-1),
            // 格式化的日期
            dateStr: null,
            // 模块列表
            chartModuleList: [
                {
                    key: "statPublishNum",
                    day1: "publishNumDayAgo",
                    day15: "publishNum15Ago",
                    title: "累计内容发布项",
                    color: "#4E74E9",
                    unit: "项",
                },
                {
                    key: "statViewNum",
                    day1: "viewNumDayAgo",
                    day15: "viewNum15Ago",
                    title: "累计居民接受服务人次",
                    color: "#EE6666",
                    unit: "人次",
                },
                {
                    key: "statJoinNum",
                    day1: "joinNumDayAgo",
                    day15: "joinNum15Ago",
                    title: "累计居民参与和互动服务人次",
                    color: "#04B78A",
                    unit: "人次",
                },
            ],
            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() >= new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
            // 社区列表
            communitys: null,
            // 详情数据
            detail: {},
        }
    },

    watch: {
        date() {
            // 处理数据
            this.onProcessData()
        },
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        // 处理数据
        this.onProcessData()
        // 监听窗口大小变化事件
        window.onresize = () => {
            for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                // 调整图表大小
                l[i].communityPostChart?.resize()
            }
        }
    },

    methods: {
        /**
         * 处理数据
         */
        onProcessData() {
            this.dateStr = this.$core.formatDate(DateUtil.getDateByDay(1, this.date), "yyyy-MM-dd")

            this.getCountDate()
            this.getChartData()
        },

        /**
         * 获取统计数据
         */
        getCountDate() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            const fields = []

            for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                let v = l[i]
                fields.push(v.key, v.day1, v.day15)
            }

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listValueCountByCode",
                {
                    dataScopeId: this.orgPath,
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"),
                    selectedField: fields,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        let data = res.data

                        for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                            let v = l[i]
                            let val = data[v.key] || 0
                            let valDay1 = data[v.day1] || 0
                            let valDay15 = data[v.day15] || 0

                            this.$set(v, "value", val)
                            this.$set(v, "value15Ago", val - valDay15)
                            this.$set(v, "valueDayAgo", val - valDay1)
                        }
                    }
                })
                .finally(this.$Message.destroy)
        },

        getChartData() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            const fields = []

            for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                let v = l[i]
                fields.push(v.key)
            }

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listCommunityValue",
                {
                    dataScopeId: this.orgPath, //范围编码
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"), //统计时间
                    selectedField: ["orgCode", "orgName", "registerNum", "onlineStatus", "onlineType", "activeUser", "onlineType", "onlineStatus", ...fields], //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        for (let idx = 0, list = this.chartModuleList; idx < list.length; idx++) {
                            let item = list[idx]

                            let demonstrate = 0
                            let trial = 0
                            let keys = []
                            let data = []

                            for (let i = 0, l = res.dataList; i < l.length; i++) {
                                let v = l[i]

                                if (v.onlineStatus === "1") {
                                    demonstrate += v[item.key]
                                } else if (v.onlineStatus === "2") {
                                    trial += v[item.key]
                                }

                                keys.push(v.orgName)
                                data.push(v[item.key] || 0)
                            }

                            item.chartKeys = keys
                            item.chartData = data
                            item.chartList = res.dataList

                            // 社区类型饼图配置
                            this.$set(item, "pieOption", {
                                tooltip: {
                                    trigger: "item",
                                },
                                color: ["#FC8452", "#04B78A"],
                                legend: {
                                    top: "5%",
                                    left: "center",
                                },
                                series: [
                                    {
                                        name: item.title,
                                        type: "pie",
                                        radius: ["40%", "48%"],
                                        emphasis: {
                                            label: {
                                                show: true,
                                                fontSize: 20,
                                                fontWeight: "bold",
                                            },
                                        },
                                        data: [
                                            { value: demonstrate, name: "试点社区" },
                                            { value: trial, name: "试用社区" },
                                        ],
                                    },
                                ],
                            })

                            // 社区数值柱形图配置
                            this.$set(item, "barOption", {
                                tooltip: {
                                    trigger: "axis",
                                    axisPointer: {
                                        type: "shadow",
                                    },
                                },
                                color: [item.color],
                                grid: {
                                    left: "3%",
                                    right: "4%",
                                    bottom: "3%",
                                    containLabel: true,
                                },
                                xAxis: [
                                    {
                                        type: "category",
                                        data: keys,
                                        axisTick: {
                                            alignWithLabel: true,
                                        },
                                    },
                                ],
                                yAxis: [
                                    {
                                        type: "value",
                                        scale: true,
                                    },
                                ],
                                series: [
                                    {
                                        name: item.title,
                                        type: "bar",
                                        barWidth: "60%",
                                        data: data,
                                    },
                                ],
                            })
                        }
                    }
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 处理社区详情
         */
        async getCommunityDetail(item) {
            if (!item.chartList) return

            let communitys = []

            for (let i = 0, l = item.chartList; i < l.length; i++) {
                let v = l[i]

                communitys.push({
                    name: v.orgName,
                    value: v.orgCode,
                })
            }

            this.communitys = communitys

            if (communitys.length > 0) {
                // 默认第一个社区
                item.community = [communitys[0].value]
                // 获取数据
                await this.getCommunityDetailData(item)
            }
        },

        /**
         * 获取社区详情数据
         */
        getCommunityDetailData(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/statDateUserByCommunity",
                {
                    dataScopeId: this.orgPath, //范围编码
                    startDate: this.$core.formatDate(item.date[0], "yyyy-MM-dd"), //开始时间
                    endDate: this.$core.formatDate(item.date[1], "yyyy-MM-dd"), //结束时间
                    communityCode: item.community?.join(",") || "",
                    selectedField: ["orgCode", "orgName", "onlineStatus", "onlineType", item.key], //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        const communitys = {}
                        const keys = []
                        const series = []

                        for (let i = 0, ks = Object.keys(data).sort((a, b) => (a > b ? 1 : -1)); i < ks.length; i++) {
                            let key = ks[i]

                            for (let idx = 0, l = data[key]; idx < l.length; idx++) {
                                let v = l[idx]

                                if (!communitys[v.orgName]) {
                                    communitys[v.orgName] = []
                                }

                                communitys[v.orgName].push(v[item.key])
                            }

                            keys.push(key)
                        }

                        for (let i = 0, ks = Object.keys(communitys); i < ks.length; i++) {
                            let key = ks[i]

                            series.push({
                                name: key,
                                type: "line",
                                smooth: true,
                                data: communitys[key],
                            })
                        }

                        const option = {
                            tooltip: {
                                trigger: "axis",
                            },
                            xAxis: {
                                type: "category",
                                boundaryGap: false,
                                data: keys,
                            },
                            yAxis: {
                                type: "value",
                                scale: true,
                            },
                            series: series,
                        }

                        item.statDateUserByCommunity = data
                        this.$set(item, "lineOption", option)
                    }
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 导出 Excel
         */
        onExport(item) {
            const data = []
            for (let i = 0, l = item.chartList; i < l.length; i++) {
                let v = l[i]
                data.push({
                    序号: i + 1,
                    类型: Street.getCommunityType(v),
                    社区名称: v.orgName,
                    [item.title]: v[item.key],
                })
            }
            // 合计
            data.push({
                序号: "街道小计：",
                [item.title]: item.value,
            })
            // 创建一个新 sheet
            const sheet = xlsx.utils.json_to_sheet(data)
            // 配置单元格宽度属性
            sheet["!cols"] = [{ wch: 10 }, { wch: 30 }, { wch: 20 }, { wch: 30 }]
            // 配置合并属性
            sheet["!merges"] = [{ s: { c: 0, r: data.length }, e: { c: 2, r: data.length } }]
            // 新建 book
            const book = xlsx.utils.book_new()
            // 将 sheet 添加到 book 中
            xlsx.utils.book_append_sheet(book, sheet, item.title)
            // 导出 excel 文件
            xlsx.writeFile(book, item.title + "-数据导出.xlsx")
        },

        /**
         * 导出 Excel
         */
        onExportByDate(item) {
            const data = []

            const statData = item.statDateUserByCommunity
            const communitys = {}

            const cols = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]

            for (let i = 0, ks = Object.keys(statData).sort((a, b) => (a > b ? 1 : -1)); i < ks.length; i++) {
                let key = ks[i]
                for (let idx = 0, l = statData[key]; idx < l.length; idx++) {
                    let v = l[idx]
                    // 不存在则创建新的结构
                    if (!communitys[v.orgName]) {
                        communitys[v.orgName] = {
                            序号: Object.keys(communitys).length + 1,
                            类型: Street.getCommunityType(v),
                            社区名称: v.orgName,
                        }
                    }
                    // 追加数值
                    communitys[v.orgName][key] = v[item.key]
                }
                // 默认宽度
                cols.push({ wch: 15 })
            }
            // 循环社区，获取社区的数据列表
            Object.keys(communitys).forEach(v => {
                data.push(communitys[v])
            })
            // 创建一个新 sheet
            const sheet = xlsx.utils.json_to_sheet(data)
            // 配置单元格宽度属性
            sheet["!cols"] = cols
            // 新建 book
            const book = xlsx.utils.book_new()
            // 将 sheet 添加到 book 中
            xlsx.utils.book_append_sheet(book, sheet, item.title)
            // 导出 excel 文件
            xlsx.writeFile(book, item.title + "-数据导出.xlsx")
        },

        /**
         * 显示详情弹窗
         */
        async onDisplayDetail(item) {
            if (!item.detail) {
                let date = new Date(this.date)
                date.setDate(date.getDate() - 7)
                // 拷贝数据
                item.detail = Object.assign({}, item, {
                    id: this.$core.randomString(),
                    date: [date, this.date],
                })
            }
            // 指定当前详情数据对象
            this.detail = item.detail
            // 获取详情数据
            await this.getCommunityDetail(this.detail)
            // 显示弹窗
            this.$refs.comp_modal.display()
        },
    },
}
</script>

<style lang="less" scoped>
.managsystem-content-operation {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .block-box {
        width: 100%;
        padding: 0 20px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        min-width: 1000px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: #333;
                margin-right: 20px;
            }

            .date-filter {
                margin-right: 20px;
            }
        }

        .content-box {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .note-box {
                background: #fff;
                width: 100%;
                padding: 15px;
                margin: 20px 0;
                display: flex;
                justify-content: space-between;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                .item-box {
                    width: calc(100% / 3 - 10px);
                    margin: 10px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-sizing: border-box;
                    border-right: 1px solid #f3f3f3;

                    &:last-child {
                        border-right: 0;
                    }

                    .title {
                        color: #333;
                        font-size: 14px;
                    }

                    .time {
                        font-size: 12px;
                        color: #999;
                    }

                    > .value-box {
                        font-size: 50px;
                        line-height: 1;
                        display: flex;
                        align-items: flex-end;
                        margin: 10px 0;
                        color: #57a3f3;

                        .unit {
                            font-size: 14px;
                            line-height: 30px;
                            margin-left: 10px;
                        }
                    }

                    .trend-box {
                        display: flex;

                        .trend-item {
                            display: flex;
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .name {
                                font-size: 12px;
                                color: #444;
                                margin-right: 10px;
                            }

                            .value {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .chart-module {
                margin-bottom: 20px;
                padding: 10px;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                &:last-child {
                    margin-bottom: 0;
                }

                .head-box {
                    margin: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 1;

                    > .title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    .date {
                        flex-grow: 1;
                        color: #999;
                        margin: 0 20px;
                    }

                    .date-filter {
                        flex-grow: 1;
                        margin: 0 20px;
                    }

                    .download-btn {
                        cursor: pointer;
                        padding: 6px 20px;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        background: #e3e3e3;
                        color: #000;
                        border-radius: 4px;
                        flex-shrink: 0;

                        .name {
                            margin-left: 10px;
                        }
                    }

                    .more {
                        cursor: pointer;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        margin-left: 20px;

                        .name {
                            margin-right: 5px;
                        }
                    }
                }

                .checkbox-box {
                    margin: 10px 10px 0 10px;
                }

                .chart-box {
                    margin-top: 10px;
                    width: 100%;
                    display: flex;

                    .chart {
                        width: 100%;
                        height: 350px;
                    }

                    .chart-left {
                        width: 350px;
                        height: 350px;
                        flex-shrink: 0;
                        margin-right: 20px;
                    }

                    .chart-right {
                        height: 350px;
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    &.detail .block-box {
        min-width: initial;

        .content-box .chart-module .chart-box .chart {
            height: 300px;
        }
    }
}
</style>
